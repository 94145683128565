import React from 'react'
import { BackTop, Divider, Row, Col } from 'antd'

import '../styles/Body.css'

const Body = () => {
    return (
        <div id="Body">
            <Divider><h1 id="about">About</h1></Divider>
            <div style={{ textAlign: 'center' }}>
                <p>First things first, I'm a Liverpool FC fan. I'm a third generation American with family lines tracing back to the red side of Liverpool, UK.</p>

                <p>By the age of 18 I was one of the top 25 amateur motocross riders in the state of Utah. I decided to retire from competitive racing to launch a motocross graphics company. At Trevex Graphics I filled a number of non-technical roles. We made several mistakes and the great recession in 2008 was the nail in the coffin for Trevex so I decided to attend college at Utah State University. While in school I volunteered for student groups and consulted for local small businesses.</p>

                <p>After graduation I spent a summer travelling before starting to work for Energy Management in their ecommerce department. When I joined we were a small department of two people. Over the course of 2.5 years we were able to grow from a side project to a major company division and increase revenue by 450%.</p>

                <p>I decided it was time for a new challenge in 2015 and joined Instructure. I started on the implementation team where I managed software projects for more than 80 schools. I then moved into a people management role, overseeing a team of project managers. After a couple years I was asked to join the product management team to work on the Platform team. I've had the privilege of working on a data transform platform that was used by thousands of schools and enabled millions of students.</p>

                <p>Just before the pandemic started in 2020 I moved to TaskEasy to help with the rebuild of their platform. I focused on the core financial integrations and scheduling products that process all of the company's services (30mm+) before moving to an incredibly fast growing restaurant tech company. I helped Bbot develop their product management framework &amp; expand the product into a number of new verticals, Bbot was acquired by DoorDash in 2022.</p>

                <p>Ready for a different kind of challenge I joined Bill.com just after their $2.5bn acquisition of Divvy. I joined the company to build out it's integrated 'one stop shop' for SMB finance departments. After successfully demonstrating the PoC I handed the project off to dozens of internal teams and left the company to join Spiff which has since been acquired by Salesforce. Today, I specalize in product operations, systems design, and M&amp;A product integration. If you've read this far feel free to reach out, trevor@trevorfullwood.com</p>
            </div>

            <Divider><h1 id="work">Work</h1></Divider>

            <h2 id="salesforce">Spiff / Salesforce</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Senior Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">August 2023 - Present</p>
                </Col>
            </Row>
            <ul>
                <li>Managed the development of the partner self-service program to unlock over $25mm of pre-launch pipeline (it’s significantly larger today)</li>
                <li>Negotiated and managed a number of multi-team/multi-department projects like instance environments</li>
            </ul>
            
            <h2 id="bill">Divvy / Bill.com</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Senior Staff Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">September 2021 - April 2023</p>
                </Col>
            </Row>
            <ul>
                <li>Managed the development of the company's first integration with Divvy ($2.5 billion acquisition)</li>
                <li>Aligned stakeholders across both organizations to establish and launch long-term strategy and contribution framework for the company's unified product</li>
                <li>Developed internal tooling strategy and roadmap to improve efficiency post-acquisition</li>
            </ul>
            
            <h2 id="bbot">Bbot</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">October 2020 - September 2021</p>
                </Col>
            </Row>
            <ul>
                <li>First PM, Established project management framework to improve velocity</li>
                <li>Created 2021 roadmap to enable growth strategy</li>
                <li>Interface daily with company founders</li>
                <li>Manage the launch of major initiatives &amp; products</li>
            </ul>

            <h2 id="taskeasy">TaskEasy</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Technical Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">February 2020 - October 2020</p>
                </Col>
            </Row>
            <ul>
                <li>Spearhead initial requirements gathering and roadmap planning for a major architectural restructure</li>
                <li>Responsible for the redesign of the core finance integrations (Netsuite and Salesforce) and scheduling services that will process more than 30 million in revenue</li>
                <li>Conduct external and internal user interviews to guide roadmap decisions and ensure we are improving user experiences</li>
                <li>Communicate product strategy with internal teams to drive product alignment across the company</li>
            </ul>

            <h2 id="instructure">Instructure</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Technical Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">June 2019 - February 2020</p>
                </Col>
            </Row>
            <ul>
                <li>Coordinated between engineering, customer success, sales, and partner vendors to deliver high-quality products</li>
                <li>Served as subject matter expert responsible for articulating customer &amp; industry needs to various internal stakeholders</li>
                <li>Translated roadmap to an actionable product backlog and prioritized backlog with engineering teams</li>
                <li>Member of IMS task force developing future standards for data exchange</li>
            </ul>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Manager, Technical Consulting</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">May 2019 - June 2019</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Manager, K12 Consulting</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">January 2018 - May 2019</p>
                </Col>
            </Row>
            <ul>
                <li>Managed a team of Technical Consultants; responsible for the technical onboarding of new customers</li>
                <li>Established and refined processes to best serve customers as the business matures</li>
                <li>Worked cross-functionally with sales, product, and training teams to ensure company &amp; customer goals are met</li>
            </ul>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Principal Implementation Consultant</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">April 2017 - January 2018</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Implementation Consultant</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">April 2015 - April 2017</p>
                </Col>
            </Row>
            <ul>
                <li>Responsible for ensuring the successful implementation of new Canvas customers. Managed projects for over 80 schools and impacted learning for thousands of students</li>
                <li>Designed processes/systems and informed customers of best practices</li>
                <li>Worked cross-functionally with internal departments to ensure customer's goals are successful</li>
            </ul>
            <br />

            <h2 id="emc">Energy Management Corporation</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Business Development Specialist</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2012 - 2015</p>
                </Col>
            </Row>
            <ul>
                <li>Managed an end-to-end redesign of the company’s ecommerce website (VFDs.com), which resulted in an immediate 50% increase in engagement and an 8% reduction in bounce rate</li>
                <li>Responsibilities included promotional planning, strategic planning, web development, project management, and marketing management</li>
                <li>Developed a cost effective marketing plan with a focus on organic search traffic which increased ecommerce revenue by over 350% in two years</li>
            </ul>

            <h2 id="trevex">Trevex Graphics</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Co-Founder</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2007 - 2010</p>
                </Col>
            </Row>
            <ul>
                <li>Established a positive market presence in the competitive motocross graphics industry</li>
                <li>Managed the introduction of the company to the market; the company, all sales and marketing materials, and the software program were developed on time for our first order</li>
                <li>Implemented a cost effective marketing plan with a heavy emphasis on events promotion, online marketing, and public relations</li>
            </ul>

            <Divider><h1 id="work">Education</h1></Divider>

            <h2 id="usu">Utah State University</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h3>Jon M. Huntsman School of Business</h3>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2009-2012</p>
                </Col>
            </Row>
            <ul>
                <li>Dual B.S. - Marketing &amp; Entrepreneurship</li>
                <li>Gary and Karen Black Scholarship Recipient</li>
                <li>Dean's List (2009, 2011)</li>
            </ul>

            <h3>Entrepreneurship Club</h3>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Executive Vice President</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2011-2012</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Vice President of Marketing</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2010-2011</p>
                </Col>
            </Row>

            <BackTop />
        </div>
    )
}

export default Body
