import React from 'react'
import { Layout, Anchor } from 'antd';
import { GithubOutlined, LinkedinOutlined, LinkOutlined } from '@ant-design/icons';
import '../styles/App.css'
import Body from './Body'

const { Content, Footer, Sider } = Layout;
const { Link } = Anchor

class App extends React.Component {
    state = {
        collapsed: false
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed

        })
    }

    render() {
        return (
            <Layout className="App">
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => { this.toggleCollapsed() }}
                    collapsed={this.state.collapsed}
                >
                    <a href="/">
                      <div className="logo" />
                    </a>
                    <Anchor>
                        <h4>About</h4>
                        <Link href="#about" title="About Me" />
                        <h4>Work</h4>
                        <Link href="#salesforce" title="Spiff / Salesforce" />
                        <Link href="#bill" title="Divvy / Bill" />
                        <Link href="#bbot" title="Bbot" />
                        <Link href="#taskeasy" title="TaskEasy" />
                        <Link href="#instructure" title="Instructure" />
                        <Link href="#emc" title="Energy Management" />
                        <Link href="#trevex" title="Trevex Graphics" />

                        <h4>Education</h4>
                        <Link href="#usu" title="Utah State" />
                    </Anchor>
                    <div className="menu-footer">
                        <div className="menu-footer-right">
                            <span>Contact Me:&nbsp;&nbsp;</span>
                            <a href="https://github.com/tfullwood" target="_blank" rel="noopener noreferrer">
                              <GithubOutlined />
                            </a>
                            <a href="https://www.linkedin.com/in/trevorfullwood/" target="_blank" rel="noopener noreferrer">
                              <LinkedinOutlined />
                            </a>
                            <a href="https://t12d.com" target="_blank" rel="noopener noreferrer">
                              <LinkOutlined />
                            </a>
                        </div>
                    </div>
                </Sider>
                <Layout onClick={this.state.collapsed === false && window.innerWidth <= 768 ? this.toggleCollapsed : null}>
                    {/* <Header style={{ background: '#fff', padding: 0 }} /> */}
                    <Content style={{ margin: '24px 16px 0' }}>
                        <Body />
                    </Content>
                    <Footer style={{ textAlign: 'center' }}></Footer>
                </Layout>
            </Layout>
        )
    }
}

export default App
